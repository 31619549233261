/* RESPONSIBLE TEAM: team-standalone */

import Model, { attr } from '@ember-data/model';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import {
  HandoffBehavior,
  IntegrationState,
  type StandalonePlatform,
} from 'embercom/objects/standalone/constants';
import { type Predicate } from 'embercom/lib/knowledge-hub/constants';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type InboundCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/inbound-bot';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type StandaloneInboundBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/standalone-inbound-bot';
const API_ROOT = '/ember/standalone/intercom_messenger_configurations';

type App = {
  hasAnyInstalledAtDate: boolean;
  hasStandalonePlatform: (platformName: StandalonePlatform) => boolean;
};

type InboundCustomBot = {
  config: InboundCustomBotConfig & { editorConfig: StandaloneInboundBotEditorConfig };
  visualBuilderObject: Workflow;
};
type Ruleset = { rulesetLinks: { object: InboundCustomBot }[] };

export type MessengerVisibility = {
  messengerEnabledForUsers: boolean;
  messengerEnabledForVisitors: boolean;
  inboundConversationsEnabledForUsers: boolean;
  inboundConversationsEnabledForVisitors: boolean;
  launcherPredicatesForAnonymous: Predicate[];
  launcherPredicatesForUsers: Predicate[];
};

export default class IntercomMessengerConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('number') declare workflowRulesetId: number;

  @attr('number', { defaultValue: HandoffBehavior.NotConfigured })
  declare handoffBehavior: HandoffBehavior;

  @use ruleset = AsyncData<Ruleset>(async ({ args }: { args: { refresh: boolean }[] }) => {
    let refresh = args[0]?.refresh;
    let ruleset = null as Ruleset | null;

    if (!refresh) {
      // Try to peek from cache first unless hard reload is requested
      ruleset = await this.store.peekRecord('matching-system/ruleset', this.workflowRulesetId);
    }

    if (!ruleset) {
      ruleset = await this.store.findRecord('matching-system/ruleset', this.workflowRulesetId);
    }

    let customBot = ruleset!.rulesetLinks.firstObject!.object;
    customBot.visualBuilderObject.editorConfig = customBot.config.editorConfig;
    return ruleset;
  });

  get integrationState() {
    let app: App = this.finStandaloneService.app;
    if (!this.isIntegrated || !this.isWorkflowValid || !app.hasAnyInstalledAtDate) {
      return IntegrationState.NotConfigured;
    }

    if (app.hasStandalonePlatform('zendesk')) {
      // Sunshine integration is optional, so if it is not configured, we can still enable the messenger
      let sunshineConfig = this.finStandaloneService.zendeskConfig?.sunshineIntegration;
      if (sunshineConfig?.isSunshineConnected && !sunshineConfig.isEnabledForIntercomMessenger) {
        if (sunshineConfig.intercomMessengerState === IntegrationState.NotConfigured) {
          return IntegrationState.Created;
        } else {
          return sunshineConfig.intercomMessengerState;
        }
      }
    }

    let visibility = this.finStandaloneService.messengerSettings?.visibility as
      | MessengerVisibility
      | undefined;
    if (
      visibility?.messengerEnabledForUsers &&
      visibility?.messengerEnabledForVisitors &&
      visibility?.inboundConversationsEnabledForUsers &&
      visibility?.inboundConversationsEnabledForVisitors
    ) {
      if (
        [visibility.launcherPredicatesForAnonymous, visibility.launcherPredicatesForUsers].any(
          this.isTestModeLauncherRules,
        )
      ) {
        return IntegrationState.TestMode;
      }
      return IntegrationState.Enabled;
    }

    return IntegrationState.Paused;
  }

  isTestModeLauncherRules(predicates: Predicate[]): boolean {
    return (
      predicates.length === 1 &&
      predicates.firstObject?.attribute === 'last_visited_url' &&
      predicates.firstObject?.comparison === 'contains' &&
      predicates.firstObject?.type === 'string' &&
      predicates.firstObject?.value === 'intercomTest=true'
    );
  }

  get isMessengerEnabled() {
    return this.integrationState === IntegrationState.Enabled;
  }

  get isMessengerNotConfigured() {
    return this.integrationState === IntegrationState.NotConfigured;
  }

  get isMessengerInTestMode() {
    return this.integrationState === IntegrationState.TestMode;
  }

  get isIntegrated(): boolean {
    let app: App = this.finStandaloneService.app;

    if (app.hasStandalonePlatform('zendesk')) {
      return this.isZendeskIntegrated;
    } else if (app.hasStandalonePlatform('salesforce')) {
      return this.isSalesforceIntegrated;
    }

    return true;
  }

  private get isSalesforceIntegrated() {
    return this.finStandaloneService.salesforceConfig?.isConnected || false;
  }

  private get isZendeskIntegrated() {
    let zendeskConfig = this.finStandaloneService.zendeskConfig;
    if (!this.finStandaloneService.zendeskConfig?.isConnected) {
      return false;
    }

    let sunshineConfig = zendeskConfig?.sunshineIntegration;
    if (sunshineConfig?.isSunshineConnected && !sunshineConfig.isMultiConversationsEnabled) {
      return false;
    }

    if (!sunshineConfig?.isSunshineConnected && this.workflowHasHandoffToZendeskAgentStep) {
      return false;
    }

    return true;
  }

  private get workflow() {
    return this.ruleset.value?.rulesetLinks.firstObject?.object?.visualBuilderObject;
  }

  private get workflowHasHandoffToZendeskAgentStep() {
    return this.workflow?.groups.any((group) => {
      return group.steps.any((step) => {
        return step.type === 'operator/visual-builder/step/handoff-to-zendesk-agent';
      });
    });
  }

  get isWorkflowValid() {
    return this.workflow?.validations.isValid || false;
  }

  async activate() {
    await post(`${API_ROOT}/${this.id}/activate`);
    await this.reloadMessengerSettingsAndZendeskConfig();
  }

  async activateTestMode() {
    await post(`${API_ROOT}/${this.id}/activate_test_mode`);
    await this.reloadMessengerSettingsAndZendeskConfig();
  }

  async deactivate() {
    await post(`${API_ROOT}/${this.id}/deactivate`);
    await this.reloadMessengerSettingsAndZendeskConfig();
  }

  private async reloadMessengerSettingsAndZendeskConfig() {
    await RSVP.all([
      this.finStandaloneService.messengerSettings.reload(),
      this.finStandaloneService.zendeskConfig?.reload(),
    ]);
  }
}
